export interface INavigationRoute {
  name: string
  displayName: string
  gate?: string | undefined
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      gate: 'dashboard-access',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'clients',
      displayName: 'clients',
      gate: 'client-access',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'restaurant',
      displayName: 'restaurant',
      gate: 'restaurant-access',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'new-order',
          displayName: 'New Order',
          gate: 'create-order',
        },
        {
          name: 'active-orders',
          displayName: 'Active Orders',
          gate: 'view-order',
        },
        {
          name: 'orders-list',
          displayName: 'Orders List',
          gate: 'view-order',
        },
        {
          name: 'menu',
          displayName: 'menu',
          gate: 'restaurant-menu-access',
        },
      ],
    },
    {
      name: 'users',
      displayName: 'menu.users',
      gate: 'user-access',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'setup',
      displayName: 'Setup',
      gate: 'setup-access',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'items',
          displayName: 'Items',
          gate: 'setup-item-access',
        },
      ],
    },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      gate: 'preference-access',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      gate: 'setting-access',
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
